import _stringify from "./lib/stringify.js";
import _parse from "./lib/parse.js";
var exports = {};
var json_stringify = _stringify.stringify;
var json_parse = _parse;

exports = function (options) {
  return {
    parse: json_parse(options),
    stringify: json_stringify
  };
}; //create the default method members with no options applied for backwards compatibility


exports.parse = json_parse();
exports.stringify = json_stringify;
export default exports;
export const parse = exports.parse,
      stringify = exports.stringify;